import React from 'react'
import { TextBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import { Store } from 'Store'
import { createClickActivity, updateLead } from 'Actions'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Button from 'components/Button'

import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'

export default function Landing () {
    const { ref, properties } = useBlock('landing.body', propertiesSchema)
    const { state, dispatch } = React.useContext(Store)

    return (
        <>
            <Header/>

            <div ref={ref}>
                <div className={styles.grid} style={{ backgroundColor: properties.backgroundColor }}>
                    <TextBlock
                        className={styles.message}
                        style={{
                            color: properties.ctaTitleColor,
                        }}
                        blockId="cta.title"
                        defaultContent={`<h3>Thank you for being a part of their story.</h3>`}
                        onAnchorClick={createClickActivity}
                    />

                    <Button
                        id={'main-cta'}
                        onClick={() => {
                            let lead = {
                                fields: state.lead.fields,
                                isComplete: true
                            }
                            updateLead(dispatch, state.code, lead).then()
                        }}
                    >Give Now</Button>
                </div>

                <div className={styles.content} style={{ backgroundColor: properties.contentBackgroundColor }}>
                    <TextBlock
                        className={styles.text}
                        blockId="content.main"
                        defaultContent={`
                            <p>Thank you for enriching the lives of our wonderful students. Your continued support means so much to our students and school. With the generosity of our incredible alumni, community, and friends, we can impact the lives of so many scholars and future community leaders.</p>
                            <p>Today I encourage you to make a gift to support students, like James, at Shift University and let them know that they aren’t in this alone.</p>
                            <p>Your support helps our incredible students prepare to become leaders, both during and after their educational experience, enabling them to thrive in the healthcare community. Without the generosity of our donors, we wouldn’t be able to make such an impact on the lives of so many scholars. Your act of giving is sincerely appreciated.</p>
                            <p>Thank you for being a part of their story,</p>
                            <p>John Smitherson ‘99, MBA 2008</p>
                            <p>Shift University Director, Student Financial Aid Office</p>
                        `}
                        onAnchorClick={createClickActivity}
                    />
                </div>
            </div>

            <Footer />
        </>
    )
}
