// @flow
import * as React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import withTheme from 'hoc/withTheme'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'

export type Props = {
    id?: string,
    children: React.Node,
    href: ?string,
    onClick: ?Function,
    to?: string,
    color?: string,
    icon?: string,
    size?: 'small' | 'medium' | 'regular' | 'large',
    padding: null | 'regular',
    outlined: boolean,
    inverted: boolean,
    rounded: boolean,
    link: boolean,
    hover: boolean,
    focus: boolean,
    active: boolean,
    loading: boolean,
    disabled: boolean,
    iconPosition: 'start' | 'end',
    iconSize?: number,
    isSubmit: Boolean,
    forwardedRef: React.Ref,
}

type LinkProps = Props & {
    href: string
}

export type ButtonProps = Props & {
    onClick: Function
}

function buildButtonClasses (props: Props, theme): Object {
    let classes = {
        [styles.button]: true,
        [styles.icon]: props.icon,
        [styles.isOutlined]: props.outlined,
        [styles.isInverted]: props.inverted,
        [styles.isSquare]: theme.buttonStyle === 'square',
        [styles.isRounded]: theme.buttonStyle === 'rounded' || props.rounded,
        [styles.isPill]: theme.buttonStyle === 'pill',
        [styles.isLink]: props.link,
        [styles.isHover]: props.hover,
        [styles.isFocus]: props.focus,
        [styles.isActive]: props.active,
        [styles.isLoading]: props.loading,
        [styles.isDisabled]: props.disabled
    }

    if (props.color) {
        classes[`__color-${props.color}`] = true
    }

    if (props.size) {
        classes[`__size-${props.size}`] = true
    }

    if (props.padding !== 'regular') {
        classes[`__padding-${props.padding || 'none'}`] = true
    }

    return classes
}

class Button extends React.Component<LinkProps | ButtonProps> {
    render () {
        const props = this.props
        const loader = { '--color-loader': `url(${props.theme.loader})` }

        let content = props.children

        if (props.icon) {
            const iconImg = <div className='sh-button__icon'>
                <img src={props.icon} alt='Icon' style={{ height: props.iconSize ? props.iconSize + 'px' : null }} />
            </div>

            content = (
                <>
                    {props.iconPosition === 'start' ? iconImg : null}
                    <div className='sh-button__text'>
                        {props.children}
                    </div>
                    {props.iconPosition === 'end' ? iconImg : null}
                </>
            )
        }

        const classes = buildButtonClasses(this.props, this.props.theme)
        const style = {
            backgroundColor: this.props.theme.backgroundColor,
            color: this.props.theme.textColor,
            ...props.style,
            ...loader
        }

        if (props.to) {
            return <Link className={cn(classes)} to={this.props.to}>{content}</Link>
        }

        return props.href
            ? <a ref={this.props.forwardedRef} id={props.id || null} href={props.href} style={style} className={cn(classes)} onClick={props.onClick}>{content}</a>
            : <button id={props.id || null} style={style} type={props.isSubmit ? 'submit' : 'button'} disabled={props.disabled} onClick={props.onClick} className={cn(classes)} ref={this.props.forwardedRef}>{content}</button>
    }
}

Button.defaultProps = {
    outlined: false,
    inverted: false,
    rounded: false,
    link: false,
    padding: 'regular',
    hover: false,
    focus: false,
    active: false,
    loading: false,
    disabled: false,
    iconPosition: 'start',
    isSubmit: false,
    onClick: () => {}
}

export default withTheme(supportedThemes)(React.forwardRef((props, forwardRef) => {
    const { properties, ref } = useBlock(`${props.blockId}.button-${props.id}`, propertiesSchema)
    let href = props.href

    if (properties.url) {
        href = properties.url
    }

    const propsOnClick = props.onClick
    let handleOnClick = React.useCallback((e) => {
        createClickActivity(href, e.target.innerText).then()
        propsOnClick()
    }, [href, propsOnClick])

    return <Button
        {...props}
        href={href}
        forwardedRef={ref}
        onClick={handleOnClick}
        style={{
            paddingTop: `${properties.verticalPadding}px`,
            paddingBottom: `${properties.verticalPadding}px`,
            paddingLeft: `${properties.horizontalPadding}px`,
            paddingRight: `${properties.horizontalPadding}px`,
            backgroundColor: properties.backgroundColor,
            color: properties.color
        }}
    >{props.children}</Button>
}))

export const ButtonGroup = ({ children, className }: { children: React.Node, className: String}) => (
    <div className={`sh-button-group ${className}`}>{children}</div>
)

ButtonGroup.defaultProps = {
    className: ''
}
