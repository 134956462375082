// @flow

import * as React from 'react'

import { useBlock } from '@shift-marketing/shift-one-website-editor'

import HeaderImage from 'components/HeaderImage'
import TopBar from 'components/TopBar'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

Header.defaultProps = {
    date: new Date()
}

export default function Header () {
    const { ref, properties } = useBlock('landing.header', propertiesSchema)

    return (
        <div
            ref={ref}
            className={styles.container}
            style={{ backgroundColor: properties.backgroundColor }}
        >
            <TopBar/>

            <div className={styles.containerGrid}>
                <HeaderImage id={1} />
                <HeaderImage id={2} />
            </div>
        </div>
    )
}
